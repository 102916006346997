<template>
    <div class="detail">
      <div>
        <div class="h-handle-bg">
          <div class="h-handle-button">
            <div class="h-b"><el-button type="primary" size="small" @click="savepasswordconfig" v-right-code="'Manage_Menu_System_PasswordConfig_AddEdit'">保存</el-button> </div>
          </div>
        </div>
      </div>
      <div class="detail-item-wrapper basic-info">
        <div class="item-title">密码策略</div>
        <div class="form-list form-list-two">
          <el-form class="inline-form-wrapper" ref="_Form" label-width="150px">
            <el-form-item label="密码长度限制：" prop="PasswordLengthLowerLimit">
              <el-input-number v-model="PassworConfig.PasswordLengthLowerLimit" :min="8" :max="20"></el-input-number>
            </el-form-item>
            <el-form-item label="最长使用期限（天）：" prop="PasswordMostUseDays">
              <el-input-number v-model="PassworConfig.PasswordMostUseDays" :min="1" :max="3650"></el-input-number>
            </el-form-item>
            <el-form-item label="密码组成：" prop="PasswordComposeMethod">
              <el-select v-model="PassworConfig.PasswordComposeMethod" placeholder="请选择">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="允许登录失败次数：" prop="PasswordRetryCount">
              <el-input-number v-model="PassworConfig.PasswordRetryCount" :min="3" :max="365"></el-input-number>
            </el-form-item>
            <el-form-item label="必须使用其他密码的次数：" prop="PasswordReUseCount">
              <el-input-number v-model="PassworConfig.PasswordReUseCount" :min="0" :max="365"></el-input-number>
            </el-form-item>
            <el-form-item label="闲置超时时间（分钟）：" prop="PasswordIdleTimeoutMinutes">
              <el-input-number v-model="PassworConfig.PasswordIdleTimeoutMinutes" :min="1" :max="1440"></el-input-number>
            </el-form-item>
            <el-form-item label="密码锁定时间（分钟）：" prop="PasswordLockMinutes">
              <el-input-number v-model="PassworConfig.PasswordLockMinutes" :min="1" :max="1440"></el-input-number>
            </el-form-item>
            <el-form-item label="是否使用一次性密码：" prop="PasswordAllowDefault">
              <el-switch v-model="PassworConfig.PasswordAllowDefault" active-color="#13ce66" inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </template>
  <script>
    export default {
      mounted() {
        this.initialize();
      },
      data() {
        return {
          options: [
            { value: 0, label: '不限制' },
            { value: 1, label: '密码+字母' },
            { value: 2, label: '密码+字母+特殊字符' },
          ],
          PassworConfig: {
            PasswordLengthLowerLimit: 0,
            PasswordMostUseDays: 0,
            PasswordComposeMethod: 0,
            PasswordRetryCount: 0,
            PasswordReUseCount: 0,
            PasswordIdleTimeoutMinutes: 0,
            PasswordLockMinutes:0,
            PasswordAllowDefault: true,
          }
        }
      },
      props: {
  
      },
      methods: {
        initialize() {
          this.$ajax.send("omsapi/syspasswordconfig/getpwdconfig", "post", null, (data) => {
            this.PassworConfig = data.Result;
          });
        },
        savepasswordconfig(){
          var _this=this;
          this.$ajax.send("omsapi/syspasswordconfig/savepasswordconfig", "post", this.PassworConfig, (data) => {
            _this.Utils.messageBox('保存成功.', "success");
            _this.initialize();
          });
        }
      },
      components: {
  
      }
    }
  </script>
  <style>
  
  </style>