<template>
    <div class="h-full">
        <div :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
            <Control :config="config" @onChangeEditDataSource="onChangeEditDataSource"></Control>
        </div>
    </div>
</template>

<script>
    import Control from './components/syspasswordconfig.vue';
    export default {
        data() {
            return {
                config: {
                    isDetailDisplay: false,
                },
                editDataSource: {
                    
                },
                initialDataSource: {}
            }
        },
        methods: {
            onChangeEditDataSource: function (dataSource) {
                
            },
            syncDataSource() {
                
            },
            checkSaveStatus() {
                this.config.isDetailDisplay = false;
            }
        },
        components: {
            "Control": Control,
        }
    }
</script>

<style>

</style>
